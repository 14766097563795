<template>
  <section
    v-loading="loading"
    :element-loading-text="'Loading icons list, please wait...'"
  >
    <div class="total-container">
      <div class="searchbar-wrapper">
        <el-input
          type="text"
          v-model="searchQuery"
          placeholder="Search icons..."
          class="search-bar"
        >
          <template #prefix> <i class="el-icon-search"></i> </template
        ></el-input>

        <el-switch
          style="margin-top: 15px"
          v-model="currentIconType"
          active-text="FILL"
          inactive-text="OUTLINE"
          active-value="FILL"
          inactive-value="OUTLINE"
        >
        </el-switch>
      </div>
      <div class="iconslist-container">
        <span
          v-for="icon in filteredIcons"
          :key="icon._id"
          class="custom-icon-wrapper"
        >
          <div
            v-html="icon.content"
            class="centered-content custom-icon icon3"
            @click.prevent="sendIconData(icon.name)"
          ></div>
          <div class="centered-content">{{ icon.name }}</div>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { fetchAllIcons } from "@/repo/iconsRepo";

export default {
  async mounted() {
    this.loading = true;
    await this.fetchIcons();
  },
  data() {
    return {
      menuIcons: [],
      searchQuery: "",
      currentIconType: "OUTLINE",
      loading: false,
    };
  },
  methods: {
    async fetchIcons() {
      try {
        let icons = await fetchAllIcons();
        this.menuIcons = icons;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching icons:", error);
      }
    },
    sendIconData(iconName) {
      this.$emit("icon-data", iconName);
    },
  },
  computed: {
    filteredIcons() {
      return this.menuIcons.filter((icon) => {
        return (
          icon.name &&
          icon.name.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
          icon.type == this.currentIconType
        );
      });
    },
  },
};
</script>

<style scoped>
.searchbar-wrapper {
  display: flex;
  justify-content: center;
}
.total-container {
  margin-bottom: 20px;
}
.search-bar {
  width: 30%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.iconslist-container {
  display: grid;
  grid-template-columns: repeat(5, 190px); 
  grid-gap: 40px;
  justify-content: center;
  align-items: center;
  max-height: 75vh;
  padding: 20px 0px;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: calc(50vh);
}

.custom-icon-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.centered-content {
  display: flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center; 
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}
.el-icon-search {
  margin-top: 18px;
  margin-left: 15px;
}

</style>
<style lang="scss">
.icon3 {
  .primary {
    fill: var(--primary-color) !important;
  }
  .stroke{
        stroke: var(--primary-color);
        fill: #FFF !important;
 }
}
</style>
